import HeaderContainer from './HeaderContainer';
import DetailPageHeader from './DetailPageHeader';
import HeaderInfoAuthorized from './HeaderInfoAuthorized';
import { PARTICLES_CONFIG } from './ParticlesAnimation';

export default {
  USE_SIGN_UP_MODAL: true,
  SORT_RACING_ODDS_MOBILE: true,
  EXPANDED_SELECTION: true,
  MIX_PROMOTIONS_BONUSES: true,
  MAX_MARKET_ROWS_COUNT: 5,
  animations: {
    bonus: true,
    particles: true,
    signupBonusParticles: true,
    PARTICLES_CONFIG,
  },
  HeaderContainer,
  DetailPageHeader,
  HeaderInfoAuthorized,
  // PaymentMethodList,
};
